.app-chatbot-container {
    margin: 40px 0;
    display: flex;
    justify-content: center;
    position: fixed;
    right: 70px;
    width: 20em;
    bottom: 40px;
    z-index: 9999;
    box-shadow: 5px 5px 13px rgba(91, 81, 81, 0.4);
    border-radius: 5px;
}

.app-chatbot-button {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: transparent;
    border: none;
    position: fixed;
    bottom: 40px;
    z-index: 9999;
    right: 40px;
    cursor: pointer;
}

@media (max-width: 600px) {
    .app-chatbot-container {
        transform: scale(0.9) translateY(26px);
    }
}